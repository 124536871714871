import { useModelBrowser } from '@/app/composable';
import { Concept } from '@/app/interfaces';
import store from '@/app/store';
import { computed, ref, Ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import { useMappingFields, useMappingPrediction } from '.';
import { FieldConfiguration, FieldPrediction, MappingConfig, Source } from '../views/mapping/mapping.types';

export function useMappingConfiguration(
    initialConfiguration: Ref<MappingConfig>,
    sample: Ref<any[]>,
    basePath: Ref<string[] | undefined>,
) {
    const { getConceptFromId, getConceptFromUid } = useModelBrowser();
    const rootConcept = computed(() => {
        return configuration.value?.concept?.id ? getConceptFromId(configuration.value.concept?.id) : undefined;
    });

    const getValidConfiguration = (configuration: MappingConfig) => {
        // if (!rootConcept?.value) return config;
        const config = R.clone(configuration);
        return {
            ...config,
            fields: config.fields.map((field: FieldConfiguration) => {
                const pathUids = [];
                const path = [];
                const parentIds = [];
                const categories = [];

                for (let p = 0; p < field.target.pathUids.length; p++) {
                    const pathUid = field.target.pathUids[p];
                    if (getConceptFromUid(pathUid)) {
                        pathUids.push(pathUid);
                        path.push(field.target.path[p]);
                        parentIds.push(field.target.parentIds[p]);
                        categories.push(field.target.categories[p]);
                    } else break;
                }

                if (pathUids.length === 0 && field.target.pathUids.length > 0 && rootConcept.value)
                    return initEmptyField(field.source);
                else if (pathUids.length !== field.target.pathUids.length)
                    return {
                        ...field,
                        target: {
                            ...field.target,
                            id: null,
                            title: null,
                            type: null,
                            pathUids,
                            path,
                            parentIds,
                            categories,
                        },
                    };
                else if (field.target.id && !getConceptFromId(field.target.id))
                    return {
                        ...field,
                        target: { ...field.target, id: null, title: null, type: null },
                    };
                return field;
            }),
        };
    };

    const {
        extractFields,
        initEmptyField,
        createFieldConfiguration,
        removeArrayBracketsFromBasePath,
    } = useMappingFields(sample, rootConcept, basePath);
    const configuration: Ref<MappingConfig> = ref<MappingConfig>(R.clone(initialConfiguration.value));
    const domains = computed(() => store.getters.dataModel.domains);
    const validationErrorPerId = ref<any>({});

    const { predict } = useMappingPrediction(configuration, basePath);

    const setConcept = (field: FieldConfiguration, concept: Concept, prediction: any = null) => {
        // If concept is multiple find how many other concepts exist with the same target id and path
        let sameMultipleFields = 0;
        if (concept?.metadata?.multiple) {
            const id = concept?.id;
            const targetPath = field.target?.path;
            sameMultipleFields = configuration.value.fields.reduce((count: number, f: any) => {
                return f.target.id === id && R.equals(f.target.path, targetPath) ? count + 1 : count;
            }, 0);
        }

        const idx = configuration.value.fields.findIndex((f: FieldConfiguration) => f.source.id === field.source.id);
        if (~idx) {
            configuration.value.fields.splice(
                idx,
                1,
                createFieldConfiguration(field, concept, prediction, false, sameMultipleFields + 1),
            );
        }
    };

    const resetMapping = async (initPredict = true) => {
        const keys = Object.keys(sample.value[0]);
        const result: Source[] = [];
        keys.forEach((key: string) => extractFields(key, [], result));
        configuration.value.fields = result.reduce((fields: FieldConfiguration[], source: Source) => {
            fields.push(initEmptyField(source));
            return fields;
        }, []);
        if (initPredict) await initPredictMapping(configuration.value.fields);
    };

    const refreshMapping = async () => {
        if (!configuration.value?.fields || sample.value.length === 0) return;

        const keys = Object.keys(sample.value[0]);
        const result: Source[] = [];
        keys.forEach((key: string) => extractFields(key, [], result));
        const initFields: FieldConfiguration[] = [];

        configuration.value.fields = result.map((source) => {
            let field = configuration.value?.fields.find(
                (f: FieldConfiguration) => f.source.title === source.title && R.equals(f.source.path, source.path),
            );
            if (field) {
                if (field.transformation) {
                    // remove array brackets from source path based on base path
                    const tempField = removeArrayBracketsFromBasePath(field);
                    const arraysInSource = tempField.source.path.join('')?.match(/\[\]/g)?.length ?? 0;
                    const arraysInTarget = tempField.target.path.join('')?.match(/\[\]/g)?.length ?? 0;

                    // if source path contains arrays and target path has more arrays than source path
                    if (tempField.source.path.some((p) => p.includes('[]')) && arraysInTarget > arraysInSource) {
                        // add oneElementArrays transformation if it does not exist
                        if (!R.has('oneElementArrays', field.transformation))
                            field = R.assocPath(['transformation', 'oneElementArrays'], [], field);
                    }
                    // otherwise remove oneElementArrays transformation
                    else field.transformation = R.omit(['oneElementArrays'], field.transformation);
                }

                return field;
            }
            const newField = initEmptyField(source);
            initFields.push(newField);
            return newField;
        });

        // make sure source ids are in sequence ion case new fields are initiated when refreshing mapping
        // since backend updates the source ids sequence, in case of adding new fields for mapping,
        // the source ids might already exists but for different fields and if source ids are not in sequence for prediction,
        // will casue duplicate fields and delete fields with the same id
        configuration.value.fields.forEach((field: FieldConfiguration, idx: number) => {
            field.source.id = idx + 1;
        });

        if (!initFields.length) return;
        await initPredictMapping(initFields);
    };

    const initPredictMapping = async (fields: any[]) => {
        const predictionConcept = configuration.value.concept?.id
            ? getConceptFromId(configuration.value.concept.id)
            : null;
        const predictionResponse: any = await predict(
            fields,
            predictionConcept && predictionConcept.referenceId
                ? predictionConcept.referenceId
                : configuration.value.concept?.id,
            configuration.value.concept?.id ? getConceptFromId(configuration.value.concept.id)?.domain : undefined,
        );

        for (let idx = 0; idx < fields.length; idx++) {
            const field: FieldConfiguration = fields[idx];
            const fieldPrediction: FieldPrediction | null = predictionResponse[field.source.id];

            if (fieldPrediction && fieldPrediction.matchings) {
                const concept = getConceptFromId(fieldPrediction.matchings.target);
                const prediction = fieldPrediction.matchings;
                setConcept(field, concept, prediction);
            }
        }
    };

    const validate = () => {
        let isValid = true;
        const regex = /\[.*?\]/;
        const hasOrder = R.has('order');
        if (configuration.value) {
            configuration.value.fields.forEach((fieldOriginal: FieldConfiguration) => {
                const field = removeArrayBracketsFromBasePath(fieldOriginal);
                if (field.temp.invalid && validationErrorPerId.value[field.source.id]?.type === 'sample') {
                    isValid = false;
                    return;
                }
                field.temp.invalid = false;
                if (field.target.id === null) return; // Not testing for empty targets
                validationErrorPerId.value[field.source.id] = { message: null, description: null };
                // Calculate duplicates, to be used below
                const duplicates = configuration.value.fields.filter((obj) => {
                    if (obj.target.id === null) return false;

                    return (
                        obj.target.id === field.target.id && obj.target.path.join('__') === field.target.path.join('__')
                    );
                });

                // Calculate duplicate alias names
                const duplicateAliases: FieldConfiguration[] = configuration.value.fields.filter((obj) => {
                    if (obj.target.id === null) return false;
                    return (
                        obj.alias?.length &&
                        field.alias?.length &&
                        obj.source.id !== field.source.id &&
                        obj.alias === field.alias
                    );
                });

                const arraysInSource = field.source.path.join('')?.match(/\[\]/g)?.length ?? 0;
                const arraysInTarget = field.target.path.join('')?.match(/\[\]/g)?.length ?? 0;

                if (field.transformation) {
                    // Missing unit transformation
                    if (
                        field.transformation.measurementType &&
                        field.transformation.measurementType !== 'Not relevant' &&
                        field.transformation.sourceUnit === null
                    ) {
                        field.temp.invalid = true;
                        validationErrorPerId.value[field.source.id].message = 'Missing measurement unit';
                    }

                    // Missing date format
                    if (field.transformation.sourceDateFormat === null) {
                        field.temp.invalid = true;
                        validationErrorPerId.value[field.source.id].message = 'Missing date format';
                    }

                    // Missing timezone
                    if (field.transformation.sourceTimezone === null) {
                        field.temp.invalid = true;
                        validationErrorPerId.value[field.source.id].message = 'Missing timezone';
                    }

                    // Missing date order
                    if (field.transformation.sourceDateFormat === 'infer' && field.transformation.dateOrder === null) {
                        field.temp.invalid = true;
                        validationErrorPerId.value[field.source.id].message = 'Missing date order';
                    }

                    // More arrays in target path than source path
                    if (R.has('oneElementArrays', field.transformation)) {
                        if (arraysInTarget - arraysInSource !== field.transformation.oneElementArrays?.length) {
                            field.temp.invalid = true;
                            validationErrorPerId.value[field.source.id].message = 'Invalid nesting levels';
                            validationErrorPerId.value[field.source.id].description =
                                'The path leading to the target field you have selected is longer (i.e. it includes more arrays) than the path of the source field. You need to define how the mapping path inconsistency should be handled.';
                        }
                    }
                }

                // Check if is array and not a multiple field
                if (regex.test(field.source.type) && !field.transformation?.multiple) {
                    field.temp.invalid = true;
                    validationErrorPerId.value[field.source.id].message = 'Invalid mapping';
                    validationErrorPerId.value[field.source.id].description =
                        'The target field you have selected for this source field (that is an array and takes multiple values) cannot take multiple values based on the data model.';
                }

                // Check for same level of nesting with arrays
                if (arraysInSource > arraysInTarget) {
                    field.temp.invalid = true;
                    validationErrorPerId.value[field.source.id].message = 'Invalid nesting levels';
                    validationErrorPerId.value[field.source.id].description =
                        'The path leading to the target field you have selected is shorter (i.e. it includes less arrays) than the path of the source field. You need to change the target path in this mapping to include more related concepts so as to be equivalent to the source path.';
                }

                if (
                    field.transformation?.multiple &&
                    field.source.type.startsWith('array [') &&
                    duplicates.length > 1
                ) {
                    field.temp.invalid = true;
                    validationErrorPerId.value[field.source.id].message = 'Invalid mapping';
                    validationErrorPerId.value[field.source.id].description =
                        'The target field you have selected for this source field appears in the mapping of multiple source fields and at least one of the source fields is an array. You need to change the mapping to another target field having in mind that it is possible to: (a) map just 1 array source field to a target field that accepts multiple values; or (b) to map multiple source fields (that are not arrays) to one target field that accepts multiple values.';
                }

                if (field.transformation && hasOrder(field.transformation)) {
                    // Multiple / Ordered fields
                    // Missing order
                    if (field.transformation.order === null) {
                        field.temp.invalid = true;
                        validationErrorPerId.value[field.source.id].message = 'Missing order';
                    } else if (
                        duplicates.filter((obj) => obj.transformation?.order === field.transformation?.order).length > 1
                    ) {
                        // Duplicate order
                        field.temp.invalid = true;
                        validationErrorPerId.value[field.source.id].message = 'Duplicate order';
                        validationErrorPerId.value[field.source.id].description =
                            'The order you have selected for the specific field is the same as in other field(s) with the same mapping.';
                    }
                } else if (duplicates.length > 1 && !field.transformation?.multiple) {
                    // Duplicate field (not multiple)
                    field.temp.invalid = true;
                    validationErrorPerId.value[field.source.id].message = 'Duplicate field';
                    validationErrorPerId.value[field.source.id].description =
                        'The target field you have selected for this source field appears in the mapping of additional source fields, but it cannot take multiple values based on the data model.';
                }

                if (duplicateAliases.length) {
                    if (!field.temp.invalid) {
                        field.temp.invalid = true;
                        validationErrorPerId.value[field.source.id].message = 'Duplicate alias';
                        validationErrorPerId.value[field.source.id].description =
                            'The alias name is used by another field';
                    }
                }

                if (hasCircularNesting(field.target.categories)) {
                    field.temp.invalid = true;
                    validationErrorPerId.value[field.source.id].message = 'Invalid mapping';
                    validationErrorPerId.value[field.source.id].description =
                        'Circular reference of the same concept. The related concept you have selected for the mapping is the same as a previous concept that appears in the target path. You need to select a different concept or remove the intermediate concepts in order to proceed.';
                }

                if (field.alias?.length && !/^[A-Za-z0-9-_]*$/.test(field.alias)) {
                    field.temp.invalid = true;
                    field.temp.invalidRegex = true;
                    validationErrorPerId.value[field.source.id].message = 'Invalid alias format';
                    validationErrorPerId.value[field.source.id].description =
                        'Alias must contain only alphanumeric characters, dashes and underscores.';
                }
                fieldOriginal.temp.invalid = field.temp.invalid;
                isValid = isValid && !field.temp.invalid;
            });
        }
        return isValid;
    };

    // returns true if there is circular nesting in a given path
    // for example, input: ["a", "b", "c", "b", "e"] will return true
    const hasCircularNesting = (inputValues: string[]) => {
        let isCircular = false;
        for (let i = 0; i < inputValues.length; i++) {
            for (let j = 0; j < inputValues.length; j++) {
                if (j >= i) break;
                if (j < i && inputValues[i] === inputValues[j]) {
                    isCircular = true;
                    break;
                }
            }
            if (isCircular) break;
        }
        return isCircular;
    };

    watch(
        () => initialConfiguration.value,
        (newConfiguration: MappingConfig) => {
            configuration.value = R.clone(newConfiguration);
        },
        { deep: true },
    );

    watch(
        () => configuration.value?.domain,
        async (
            newDomain: {
                id: number;
                name: string;
            } | null,
            oldDomain: any,
        ) => {
            if (oldDomain !== null && newDomain?.id !== oldDomain?.id) {
                configuration.value.concept = null;
                configuration.value.standard = null;
            }
        },
        { deep: true, immediate: true },
    );

    return {
        configuration,
        domains,
        validationErrorPerId,
        rootConcept,
        predict,
        resetMapping,
        initEmptyField,
        setConcept,
        validate,
        refreshMapping,
        getValidConfiguration,
        initPredictMapping,
    };
}
