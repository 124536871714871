import { useAxios, useExecutionErrors } from '@/app/composable';
import { useSockets } from '@/app/composable/socket';
import { ExecutionErrorCategory } from '@/app/store/execution-errors';
import { ExecutionType, MessageType } from '@/modules/workflow-designer/constants';
import { Ref, ref } from '@vue/composition-api';
import { JobsAPI } from '../api';
import { DCJStep, StatusCode } from '../constants';
import { DataCheckinJob, DataCheckinJobStep } from '../types';

export function useSampleRun(
    step: Ref<DataCheckinJobStep | null>,
    job: Ref<DataCheckinJob | null>,
    root: any,
    updateProcessedSample: Function,
) {
    const { joinSocketRoom, WebSocketsRoomTypes } = useSockets();
    const { errorMessage, getExecutionError } = useExecutionErrors();

    const loadingSampleRun = ref<boolean>(false);
    const processedSample = ref<any>(null);
    const statusCode = ref<StatusCode>(StatusCode.Configuration);
    const skipSampleRun = ref<boolean>(
        !!process.env.VUE_APP_SKIP_DCJ_SAMPLE_RUN && process.env.VUE_APP_SKIP_DCJ_SAMPLE_RUN === 'true',
    );

    const getProcessedSample = async () => {
        if (!step.value) return;
        const { exec } = useAxios();
        exec(JobsAPI.getStepById(step.value.id)).then((res: any) => {
            updateProcessedSample(res.data.processedSample);
        });
    };

    const isCleaningAndEmptyData = (data: any) => {
        return (
            step.value?.dataCheckinStepType?.name === DCJStep.Cleaning &&
            data.body?.errors?.input_records > 0 &&
            data.body?.errors?.output_records === 0
        );
    };

    const onMessage = async (data: any) => {
        if (data.type === MessageType.Status && data.body.executionType === ExecutionType.Sample) {
            switch (data.body.status) {
                case StatusCode.Completed:
                    await getProcessedSample();
                    loadingSampleRun.value = false;
                    break;
                case StatusCode.Failed:
                case StatusCode.Cancelled:
                    if (root && !isCleaningAndEmptyData(data)) {
                        // Check if the error is a harvester error
                        const harvesterError = data.body.executionErrors.find((err: any) =>
                            getExecutionError(err.error_code, ExecutionErrorCategory.Harvester),
                        );

                        const message = harvesterError
                            ? `Sample run failed. ${errorMessage(harvesterError.error_code).error.description}`
                            : data.message ?? 'Sample run failed';

                        root.$toastr.e(message, 'Sample Error');
                    }
                    loadingSampleRun.value = false;
                    updateProcessedSample(null, data.body.errors);
                    break;
                default:
                // do nothing
            }
        }
    };

    const executeSampleRun = async () => {
        if (!step.value) return;
        loadingSampleRun.value = true;
        joinSocketRoom(WebSocketsRoomTypes.Workflow, job.value?.workflowId);
        const { exec } = useAxios();
        await exec(JobsAPI.executeSampleRun(step.value.id));
    };

    return {
        skipSampleRun,
        executeSampleRun,
        loadingSampleRun,
        processedSample,
        statusCode,
        onMessage,
    };
}
