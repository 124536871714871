import { useAxios, useFeatureFlags } from '@/app/composable';
import { computed, Ref } from '@vue/composition-api';
import { clone, equals, isEmpty, omit } from 'ramda';
import { v4 as uuidv4 } from 'uuid';
import { AssetsAPI } from '../../asset/api';
import { StatusCode as AssetStatusCodes } from '../../asset/constants';
import { JobsAPI, ModelAPI } from '../api';
import { StatusCode } from '../constants';
import { DataCheckinJob, DataCheckinJobStep } from '../types';
import { Condition, Constraint } from '../views/cleaning/cleaning.types';
import { useJobConfiguration } from './config-extraction';
import { useSampleFields } from './sample-fields';

export interface UseStepsConfig {
    jobId: number;
    stepName: string;
}

export function useStep(
    step: Ref<DataCheckinJobStep | null>,
    job: Ref<DataCheckinJob | null>,
    root: any,
    queryParams = '{}',
) {
    const { getStructure, getLoaderSchema } = useJobConfiguration(job);
    const { extractMappingFieldNames } = useSampleFields();
    const { isEnabled: isFeatureEnabled } = useFeatureFlags();

    /**
     * Checks if a step has been finalized
     */
    const isFinalized = computed<boolean>(
        () => !!step.value && step.value.status !== StatusCode.Configuration && step.value.status !== StatusCode.Update,
    );

    /**
     * Checks if a step has been deprecated
     */
    const isDeprecated = computed<boolean>(() => step?.value?.status === StatusCode.Deprecated);

    /**
     * allow step restart only if the step has failed, the job has never been executed successfully
     * (volume is 0) and if file or recurring api/ kafka/ externalKafka type of job
     */
    const canRestart = computed(
        () =>
            step.value &&
            step.value.status === StatusCode.Failed &&
            (!job.value?.asset || !job.value?.asset?.volume || job.value?.asset?.volume?.value === 0),
    );

    /**
     * Checks if a configuration is empty. We consider a configuration empty if the only properties
     * it has are 'files' and/or 'scheduler'
     */
    const isConfigEmpty = (configuration: any): boolean =>
        configuration === null || isEmpty(omit(['files', 'scheduler'], configuration));

    const getNextStep = (): any => {
        return new Promise((resolve, reject) => {
            const { exec } = useAxios(true);
            if (!step.value) {
                reject();
            } else {
                exec(JobsAPI.retrieveNextStep(step.value.id))
                    .then(async (stepResponse: any) => {
                        await exec(JobsAPI.getStepType(stepResponse.data.dataCheckinStepTypeId)).then(
                            (stepTypeResponse: any) => {
                                resolve({ ...stepTypeResponse.data, status: stepResponse.data.status });
                            },
                        );
                    })
                    .catch(() => resolve(null));
            }
        });
    };

    /**
     * Replaces the keys of an object with new keys
     * @param object An object
     * @param oldKeys The keys of an object
     * @param newKeys The new keys to replace old keys with
     */
    const renameFields = (object: object, oldKeys: string[], newKeys: string[]) => {
        const clonedObj = clone(object);
        oldKeys.forEach((key: string, index: number) => {
            const targetKey = clonedObj[key];
            delete clonedObj[key];
            clonedObj[newKeys[index]] = targetKey;
        });
        return clonedObj;
    };

    /**
     * Renames the sample's old field names with their mapped field ids
     * @param fields The fields as they are in the mapping configuration (source/target)
     * @param sample The job's sample
     */
    const renameSampleFields = (fields: any[], sample: any[]) => {
        // The sample's old field names
        const oldKeys = fields.map((field: any) => {
            if (field.source.path.length > 0) {
                return `${field.source.path.join('__')}__${field.source.title}`;
            }
            return field.source.title;
        });
        return sample.map((row: any) =>
            renameFields(
                row,
                oldKeys,
                fields.map((field: any) => field.target.id),
            ),
        );
    };

    const retrieveJobStep = (dcj: any, stepName: string) => {
        if (dcj) {
            return dcj.dataCheckinJobSteps.find((jobStep: any) => jobStep.dataCheckinStepType.name === stepName);
        }

        return null;
    };

    const getDroppedFields = async (jobId: number) => {
        if (!isFeatureEnabled('anonymisation')) return [];
        const { exec } = useAxios(true);
        try {
            const res = await exec(JobsAPI.getStep(jobId, 'anonymiser'));
            const anonymisation = res?.data;
            if (!isConfigEmpty(anonymisation.configuration)) {
                return (anonymisation.configuration.fields as any[]).filter(
                    (field: any) =>
                        field.anonymisationType === 'identifier' && field.options.anonymisationMethod === 'drop',
                );
            }
        } catch (err) {
            return [];
        }
        return [];
    };

    const updateAssetAfterFailedStep = async (dcj: any) => {
        const { exec } = useAxios(true);
        const harvester = retrieveJobStep(dcj, 'harvester');
        const mapping = retrieveJobStep(dcj, 'mapping');
        const cleaning = retrieveJobStep(dcj, 'cleaning');
        const encryption = retrieveJobStep(dcj, 'encryption');
        const loader = retrieveJobStep(dcj, 'loader');

        let dataset: any = null;
        await exec(AssetsAPI.getAsset(loader.configuration.collection)).then(async (res: any) => {
            dataset = res.data;
        });

        // update asset's fields based on each job step
        if (mapping) {
            if (!isConfigEmpty(mapping.configuration)) {
                const conceptIds = {
                    conceptIds: mapping.configuration.fields
                        .flatMap((field: any) => [...field.target.parentIds, field.target.id])
                        .filter((fieldId: any) => fieldId !== null),
                };
                conceptIds.conceptIds.push(mapping.configuration.domain.id); // domain id
                conceptIds.conceptIds.push(mapping.configuration.concept.id); // primary concept id
                const res = await exec(ModelAPI.conceptsUids(conceptIds));
                const conceptUids = res?.data;
                dataset.standard = mapping.configuration.standard;
                const droppedFields = await getDroppedFields(dcj.id);
                dataset.structure = await getStructure(
                    harvester.configuration,
                    mapping.configuration,
                    conceptUids,
                    droppedFields,
                );
                dataset.volume.unit = dataset.structure.type === 'json' ? 'records' : 'files';
                dataset.processingRules.mappingRules = mapping.configuration.fields.filter((obj: any) => {
                    return 'target' in obj && 'id' in obj.target && obj.target.id;
                });

                if (dataset.metadata.extent) {
                    if (dataset.metadata.extent.spatialCoverage && dataset.metadata.extent.spatialCoverage.field) {
                        const spatialCoverageFieldExists = dataset.structure.spatialFields.find(
                            (sfield: any) => sfield.name === dataset.metadata.extent.spatialCoverage.field.name,
                        );
                        if (!spatialCoverageFieldExists) {
                            dataset.metadata.extent.spatialCoverage = null;
                            dataset.status = AssetStatusCodes.Incomplete;
                        }
                    }

                    if (dataset.metadata.extent.temporalCoverage && dataset.metadata.extent.temporalCoverage.field) {
                        const temporalCoverageFieldExists = dataset.structure.temporalFields.find(
                            (tfield: any) => tfield.name === dataset.metadata.extent.temporalCoverage.field.name,
                        );
                        if (!temporalCoverageFieldExists) {
                            dataset.metadata.extent.temporalCoverage = null;
                            dataset.status = AssetStatusCodes.Incomplete;
                        }
                    }
                }
            }
        }

        if (cleaning) {
            if (!isConfigEmpty(cleaning.configuration)) {
                dataset.processingRules.cleaningRules = cleaning.configuration.fields;
            }
        }

        if (encryption) {
            if (!isConfigEmpty(encryption.configuration)) {
                dataset.processingRules.encryptionRules = encryption.configuration.fields.map((field: any) => {
                    return {
                        id: field.id,
                        uid: field.uid,
                        index: field.index,
                        encrypt: true,
                    };
                });
            }
        }

        // must update loader configuration before finalizing its previous step
        loader.configuration.schema = getLoaderSchema(dataset.structure, mapping.configuration);
        await exec(
            JobsAPI.updateStep(loader.id, {
                configuration: loader.configuration,
                serviceVersion: process.env.VUE_APP_LOADER_VERSION,
            }),
        );

        delete dataset.accessLevel;
        delete dataset.policies;
        await exec(AssetsAPI.updateAsset(dcj.asset.id, dataset, false));
    };

    /**
     * @param field new field added in revised mapping
     * @returns mapped field with added fields required in anonymisation step
     */
    const addAnonymisationOptionsToField = (field: any) => {
        const obj = clone(field);
        obj.anonymisationType = 'insensitive';
        obj.generalisation = null;
        obj.options = null;
        obj.anonymisationIdentifier = uuidv4();
        return obj;
    };

    /**
     *
     * @param field new field added in revised mapping
     * @param config configuration of encryption step
     * @param conceptUids
     * @returns mapped field with added fields required in encryption step
     */
    const addEncryptionOptionsToField = (field: any, config: any, conceptUids: any) => {
        const obj = clone(field);
        obj.index = false;
        obj.uid = conceptUids[field.id].uid;
        let currentConcept = config.schema[field.path[0]];
        for (let i = 1; i < field.path.length; i++) {
            const fieldName = field.path[i];
            currentConcept = currentConcept.children.find(
                (concept: any) =>
                    concept.key === fieldName ||
                    (fieldName.endsWith('[]') && fieldName.substring(0, fieldName.length - 2) === concept.key),
            );
        }
        const fieldInSchema = currentConcept.children.find(
            (concept: any) =>
                concept.key === field.title ||
                (field.title.endsWith('[]') && field.title.substring(0, field.title.length - 2) === concept.key),
        );
        const { indexES } = fieldInSchema;
        obj.canBeIndexed = indexES;
        return obj;
    };

    const getSchema = () => {
        return new Promise<any>((resolve) => {
            const { exec } = useAxios(true);
            let harvester: any = null;
            let mapping: any = null;
            if (step.value) {
                exec(JobsAPI.getStep(step.value.dataCheckinJobId, 'harvester')).then((resHarvester: any) => {
                    harvester = resHarvester.data;
                    if (step.value) {
                        exec(JobsAPI.getStep(step.value.dataCheckinJobId, 'mapping')).then((resMapping: any) => {
                            mapping = resMapping.data;
                            if (!isConfigEmpty(mapping.configuration)) {
                                const conceptIds = {
                                    conceptIds: mapping.configuration.fields
                                        .flatMap((field: any) => [...field.target.parentIds, field.target.id])
                                        .filter((fieldId: any) => fieldId !== null),
                                };
                                conceptIds.conceptIds.push(mapping.configuration.domain.id); // domain id
                                conceptIds.conceptIds.push(mapping.configuration.concept.id); // primary concept id
                                let conceptUids: any = null;

                                exec(ModelAPI.conceptsUids(conceptIds)).then(async (res: any) => {
                                    conceptUids = res.data;

                                    const droppedFields = await getDroppedFields(
                                        step.value?.dataCheckinJobId as number,
                                    );
                                    const structure = await getStructure(
                                        harvester.configuration,
                                        mapping.configuration,
                                        conceptUids,
                                        droppedFields,
                                    );
                                    const schema = getLoaderSchema(structure, mapping.configuration);
                                    resolve({ schema, conceptUids });
                                });
                            }
                        });
                    }
                });
            }
        });
    };

    /**
     * @param config configuration of the step
     * @param stepName
     * @param fieldsFromMapping fields extracted from mapping
     * @returns Updated configuration schema (if encryption step) and fields (by adding the ones that were added in
     *          revised mapping step, but not yet in the current step's configuration)
     */
    const addRevisedMappingNewFields = async (config: any, stepName: string, fieldsFromMapping: any) => {
        const stepConfig = clone(config);
        const addedFields: any = [];

        if (stepName === 'cleaning') {
            fieldsFromMapping.forEach((ef: any) => {
                const fieldExists = config.fields.find((cf: any) => cf.id === ef.id);

                if (!fieldExists) {
                    const fieldToBeAdded = clone(ef);
                    fieldToBeAdded.constraints = [];
                    addedFields.push(fieldToBeAdded);
                }
            });
        } else if (stepName === 'anonymiser') {
            fieldsFromMapping.forEach((ef: any) => {
                const fieldExists = config.fields.find((cf: any) => cf.id === ef.id);
                if (!fieldExists) {
                    const fieldToBeAdded = addAnonymisationOptionsToField(ef);
                    addedFields.push(fieldToBeAdded);
                }
            });
        }
        if (stepName === 'encryption') {
            await getSchema().then((resSchema: any) => {
                const { conceptUids } = resSchema;
                stepConfig.schema = { ...resSchema.schema };
                fieldsFromMapping.forEach((ef: any) => {
                    const fieldExists = config.fields.find((cf: any) => cf.id === ef.id);

                    if (!fieldExists) {
                        const fieldToBeAdded = addEncryptionOptionsToField(ef, stepConfig, conceptUids);
                        addedFields.push(fieldToBeAdded);
                    }
                });
            });
        }

        stepConfig.fields = [...stepConfig.fields, ...addedFields];

        return stepConfig;
    };

    const detailsAreValid = (
        details: { field?: string | null; conditions?: { field?: string | null; logicalOperator?: string | null }[] },
        removedField: string,
    ) => {
        // remove constraints that have field in details which is removed
        if (details.field && removedField === details.field) return false;

        if (details.conditions) {
            // remove any conditions that use a removed field
            details.conditions = details.conditions.filter((c) => !c.field || removedField !== c.field);
            // remove constraints that have no conditions
            if (details.conditions.length === 0) return false;
            // remove logical operator if only one condition
            if (details.conditions.length === 1 && details.conditions[0].logicalOperator)
                details.conditions[0].logicalOperator = null;
        }

        return true;
    };

    const conditionIsValid = (condition: Condition, removedField: string) => {
        // remove constraints that have fieldName which is removed
        if (condition.fieldName && removedField === condition.fieldName) return false;

        // remove constraints that have invalid details
        if (condition.details && !detailsAreValid(condition.details, removedField)) return false;

        if (condition.conditions) {
            // remove any conditions that use a removed field
            condition.conditions = filterCleaningConditions(condition.conditions, removedField);
            // remove constraints that have no conditions
            if (condition.conditions.length === 0) return false;
        }
        return true;
    };

    // removes any conditions that use a specific field
    const filterCleaningConditions = (conditions: Condition[], removedField: string) => {
        return conditions.reduce((acc: Condition[], condition: Condition) => {
            if (conditionIsValid(condition, removedField)) acc.push(condition);
            return acc;
        }, []);
    };

    const findField = (
        field: { id: number; parentIds: number[]; originalPath: string[]; originalName: string },
        fields: { id: number; parentIds: number[]; originalPath: string[]; originalName: string; modified?: any }[],
    ) =>
        fields.find(
            (field2) =>
                equals([...field.originalPath, field.originalName], [...field2.originalPath, field2.originalName]) &&
                equals([...field.parentIds, field.id], [...field2.parentIds, field2.id]),
        );

    /**
     * @param mappingFields fields configured in mapping
     * @param configuration configuration of the current step
     * @returns Step configuration with updated fields and schema (for encryption step)
     */
    const setupUpdatedConfiguration = (mappingFields: any, configuration: any) => {
        const config = clone(configuration);
        const extractedFieldsFromMapping = extractMappingFieldNames(mappingFields);
        const configFields = clone(configuration.fields);

        // calculate and remove deleted (from mapping) target fields
        configFields.forEach((cf: any) => {
            const mappedField = findField(cf, extractedFieldsFromMapping);

            if (mappedField) {
                if ('modified' in mappedField) {
                    // locate the revised/ modified mappings and add them as modified to the configuration of the step
                    const idx = config.fields.indexOf(findField(cf, config.fields));
                    if (idx >= 0) config.fields[idx].modified = mappedField.modified;
                }
            } else {
                const idx = config.fields.indexOf(findField(cf, config.fields));

                if (idx >= 0) {
                    config.fields.splice(idx, 1);
                    const removedField = cf.name;

                    // remove conditions in advanced rules that use any deleted fields from mapping
                    if (step.value?.order === 2 /** CLEANING */) {
                        for (let i = 0; i < config.fields.length; i++) {
                            const configField = config.fields[i];
                            const beforeField = JSON.stringify(configField);

                            configField.constraints = configField.constraints.reduce(
                                (acc: Constraint[], constraint: Constraint) => {
                                    // check simple cleaning rules
                                    if (constraint.details && detailsAreValid(constraint.details, removedField))
                                        acc.push(constraint);

                                    // check complex cleaning rules
                                    if (constraint.structure) {
                                        // remove any conditions that use a removed field
                                        constraint.structure.conditions = filterCleaningConditions(
                                            constraint.structure.conditions,
                                            removedField,
                                        );
                                        // keep constraints that have conditions
                                        if (constraint.structure.conditions.length > 0) acc.push(constraint);
                                    }
                                    return acc;
                                },
                                [],
                            );

                            const afterField = JSON.stringify(configField);
                            if (beforeField !== afterField) configField.modified = true;
                        }
                    }
                }
            }
        });

        return addRevisedMappingNewFields(
            config,
            step.value ? (step?.value as any).dataCheckinStepType.name : null,
            extractedFieldsFromMapping,
        );
    };

    const lockDcj = async (dcjId: number) => {
        const { exec: lockExec } = useAxios(true);
        lockExec(JobsAPI.lock(dcjId))
            .then(() => {
                return;
            })
            .catch((e: { response: { status: any } }) => {
                if (e.response) {
                    switch (e.response.status) {
                        case 403:
                            (root as any).$toastr.e('The Data Check-in Pipeline is locked by another user', 'Error');
                            break;
                        default:
                            (root as any).$toastr.e('Retrieving Data Check-in Pipeline failed', 'Error');
                    }
                }
                root.$router.push({ name: 'data-checkin-jobs', query: JSON.parse(queryParams) });
            });
    };

    /**
     * Returns the processed sample of the previous step.
     */
    const getPreviousProcessedSample = async () => {
        const { exec } = useAxios(true);
        if (!job.value || !step.value) return;
        const resSteps = await exec(JobsAPI.getJobSteps(job.value.id));
        const jobSteps = resSteps?.data;
        switch (step.value.order) {
            // In case of mapping, return harvester processed sample (if exists) or job sample.
            case 1: {
                const harvester = jobSteps.find((s: DataCheckinJobStep) => s.order === 0);
                if (harvester?.processedSample) return harvester.processedSample;
                return job.value?.sample;
            }
            case 2: {
                // In case of cleaning, return mapping processed sample.
                const mapping = jobSteps.find((s: DataCheckinJobStep) => s.order === 1);
                return mapping?.processedSample ?? null;
            }
            case 3: {
                // In case of anonymiser, return cleaning (if exists) or mapping processed sample.
                const cleaning = jobSteps.find((s: DataCheckinJobStep) => s.order === 2);
                if (cleaning) return cleaning.processedSample ?? null;
                const mapping = jobSteps.find((s: DataCheckinJobStep) => s.order === 1);
                return mapping?.processedSample ?? null;
            }
            default:
                return null;
        }
    };

    return {
        isConfigEmpty,
        isFinalized,
        isDeprecated,
        getNextStep,
        renameSampleFields,
        updateAssetAfterFailedStep,
        canRestart,
        setupUpdatedConfiguration,
        getSchema,
        lockDcj,
        getPreviousProcessedSample,
        findField,
    };
}
